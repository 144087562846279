import axios from "axios";
import { Field, Form, Formik } from "formik";
import { graphql } from "gatsby";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { WhitePaperVector } from "../assets/backgrounds";
import { Chevron } from "../assets/icons";
import { CloudIcon } from "../assets/icons/cloud_icon";
import CustomButton from "../components/CustomButton";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  FDA_GUIDE_PDF_LINK,
  HUBSPOT_PROXY_URL,
  WHITE_PAPER_FINAL_PDF_LINK,
  YOUTUBE_VOS_DATASET_PDF_LINK,
  emailRegex,
} from "../constants";
import "../styles/page/dicom_page.scss";
import {
  FIRST_NAME_VALIDATION,
  LAST_NAME_VALIDATION,
  WORK_EMAIL_VALIDATION,
  isDomainBlocked,
  parseURL,
} from "../utilities/helpers";

const Whitepaper = ({ location, data }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  /**
   * Custom validation function
   */
  const _data = data?.prismicWhitepaperLpPage?.data || {};
  const uid = data?.prismicWhitepaperLpPage?.uid;

  const RequestTrialSchema = Yup.object().shape({
    firstName: FIRST_NAME_VALIDATION,
    lastName: LAST_NAME_VALIDATION,
    workEmail: WORK_EMAIL_VALIDATION,
    // phone: Yup.string().nullable(true),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const [cookie] = document.cookie
      .split("; ")
      .filter((x) => x.includes("hubspotutk="));
    const [, hubspotutk] = cookie?.split("=") || ["", ""];
    setError("");
    try {
      if (
        emailRegex.test(values?.workEmail) &&
        !isDomainBlocked(values?.workEmail)
      ) {
        setLoading(true);

        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "firstname",
              value: values?.firstName,
            },
            {
              objectTypeId: "0-1",
              name: "lastname",
              value: values?.lastName,
            },
            {
              objectTypeId: "0-1",
              name: "email",
              value: values?.workEmail,
            },
            // {
            //   objectTypeId: "0-1",
            //   name: "phone",
            //   value: values?.phone,
            // },
          ].filter((x) => x.value),
        };
        if (hubspotutk) {
          Object.assign(requestPayload, {
            context: {
              pageUri: location?.href,
              pageName: parseURL(location.href) || "whitepaper",
            },
          });
        }

        let formId;
        let pdfLink;

        switch (uid) {
          case "how-to-adopt-data-centric-ai-approach":
            formId = "cc96010f-d9a6-42e1-a736-c9ce8ffc8a78";
            pdfLink = WHITE_PAPER_FINAL_PDF_LINK;
            break;
          case "youtube-vos-dataset":
            formId = "fd56a7d8-ebd9-4049-b8ad-4efe9d30105f";
            pdfLink = YOUTUBE_VOS_DATASET_PDF_LINK;
            break;
          default:
            formId = "155006ff-4fe4-4783-9423-286933426cf4";
            pdfLink = FDA_GUIDE_PDF_LINK;
            break;
        }

        const resopnse = await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId: "25381551",
            formId: _data?.hs_form_id?.text || formId,
          })
        );
        /**
         * Append custom properties to Heap
         */
        if (window.heap) {
          window.heap.addUserProperties({
            marketing_email: values?.workEmail,
            use_case: values?.useCaseFreeTextForm,
          });
        }
        if (resopnse?.data?.inlineMessage) {
          toast.success(
            <div
              dangerouslySetInnerHTML={{
                __html: resopnse?.data?.inlineMessage,
              }}
            />
          );

          window.open(pdfLink, "_blank");
        }
        resetForm();
      } else {
        setError("Email must be formatted correctly.");
      }
    } catch (error) {
      if (
        error?.response?.data &&
        error?.response?.data.errors &&
        error?.response?.data.errors[0]?.message
      ) {
        const [, errorMessage] =
          error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
        if (errorMessage) {
          setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
        }
      } else {
        setError("Please enter your work e-mail.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout location={location} theme="purple">
      <article className="overflow-hidden rsna-bg">
        <div className="relative lg:pt-44 sm:pt-12 pt-28">
          <div className="absolute z-0 hidden lg:bottom-0 bottom-72 lg:left-0 sm:block">
            <WhitePaperVector />
          </div>
          <article className="px-5 mx-auto max-w-7xl lg:px-14 sm:px-6 relative lg:pb-36 sm:pb-7.5 pb-14">
            <section className="flex flex-col-reverse justify-around lg:flex-row sm:flex-col lg:gap-x-10 gap-y-6">
              <div className="w-full ">
                <section className="relative flex flex-col items-center mx-auto lg:items-start lg:mt-0 sm:mt-14 lg:px-0 sm:px-10 lg:gap-y-10 gap-y-6">
                  <h1 className="font-manrope font-bold text-3.25xl lg:leading-tight tracking-tighter_3 lg:text-5.5xl lg:max-w-md max-w-sm lg:mx-0 mx-auto xl:pr-10 lg:pr-0 px-2 lg:text-left text-center sm:block hidden">
                    {_data?.page_heading?.text}
                  </h1>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: _data?.page_description?.html,
                    }}
                    className="max-w-xl mx-auto text-base text-center tracking-tighter_3 lg:max-w-2xl lg:mx-0 lg:pr-12 lg:text-left text-gray-1500"
                  />
                  {uid === "youtube-vos-dataset" && (
                    <CustomButton
                      link={"https://docs.encord.com/docs/data-datasets"}
                      text={"Read the documentation"}
                      icon={<Chevron />}
                      direction="right"
                      target="_blank"
                      buttonClass="bg-white text-[#1E0842]"
                    />
                  )}
                  {location?.pathname.includes(
                    "how-to-adopt-data-centric-ai-approach"
                  ) && (
                    <div className="whitepaper-card flex sm:flex-row flex-col sm:gap-x-4 gap-y-4 items-center py-5 sm:px-5 px-2.5">
                      <img
                        src={_data?.card_image?.url}
                        width={0}
                        height={0}
                        className="h-20 w-14"
                        alt="card_image"
                      />
                      <div>
                        <h2 className="text-lg font-semibold text-center tracking-tighter_3 sm:text-left">
                          {_data?.card_heading?.text}
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: _data?.card_description?.html,
                          }}
                          className="whitepaper-card-content"
                        />
                      </div>
                    </div>
                  )}
                </section>
              </div>
              <div className="self-center w-full max-w-xl xl:max-w-lg lg:max-w-sm">
                <h1 className="text-3.25xl tracking-tighter_3 font-semibold max-w-xs mx-auto mb-6 text-center sm:hidden">
                  {_data?.page_heading?.text}
                </h1>
                <section className="flex flex-col xl:px-9 lg:px-7 sm:px-9 px-5 xl:py-10 lg:py-8 py-10 w-full rounded-2.5xl bg-white">
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      workEmail: "",
                      // phone: "",
                      // useCaseFreeTextForm: "",
                    }}
                    validationSchema={RequestTrialSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      setTouched,
                      validateForm,
                      setFieldValue,
                      form,
                      isValid,
                      dirty,
                      ...rest
                    }) => {
                      return (
                        <Form className={_data?.form_class?.text}>
                          <div className="flex flex-col gap-y-2.5 w-full text-xs md:text-sm">
                            <div className="flex items-center sm:gap-x-2 gap-x-1 tracking-tighter_1 mb-3.5 sm:ml-0">
                              <CloudIcon />
                              <h3 className="text-2xl font-semibold text-purple-1100 tracking-tighter_1">
                                {_data?.form_heading?.text}
                              </h3>
                            </div>
                            <div className="flex flex-col w-full">
                              <label className="text-gray-2500 placeholder:text-[#a8a4a4] font-medium mb-1.5">
                                First name
                                <sup className="text-red-500">*</sup>
                              </label>
                              <Field
                                name="firstName"
                                placeholder="Enter your first name"
                                type="text"
                                className="text-gray-2500 placeholder:text-[#a8a4a4] py-2.5 pl-5 rounded-lg placeholder-gray-1600 border-solid border-gray-2200 border-[0.5px]"
                              />
                              {errors.firstName && touched.firstName ? (
                                <small className="text-red-500">
                                  {errors.firstName}
                                </small>
                              ) : null}
                            </div>

                            <div className="flex flex-col w-full">
                              <label className="text-gray-2500 placeholder:text-[#a8a4a4] font-medium mb-1.5">
                                Last name
                                <sup className="text-red-500">*</sup>
                              </label>
                              <Field
                                name="lastName"
                                placeholder="Enter your last name"
                                type="text"
                                className="text-gray-600 placeholder:text-[#a8a4a4] rounded-lg py-2.5 pl-5 placeholder-gray-2200 border-solid border-gray-2200 border-[0.5px]"
                              />
                              {errors.lastName && touched.lastName ? (
                                <small className="text-red-500">
                                  {errors.lastName}
                                </small>
                              ) : null}
                            </div>

                            <div className="flex flex-col gap-3 lg:gap-5 lg:flex-row">
                              <div className="flex flex-col flex-1 w-full">
                                <label className="text-gray-600 placeholder:text-[#a8a4a4] font-medium mb-1.5">
                                  Work email
                                  <sup className="text-red-500">*</sup>
                                </label>
                                <Field
                                  name="workEmail"
                                  placeholder="Enter your work email"
                                  type="text"
                                  className="text-gray-600 placeholder:text-[#a8a4a4] rounded-lg py-2.5 pl-5 placeholder-gray-1600 border-solid border-gray-2200 border-[0.5px]"
                                />
                                {errors.workEmail && touched.workEmail ? (
                                  <small className="text-red-500">
                                    {errors.workEmail}
                                  </small>
                                ) : null}
                              </div>
                            </div>
                            {error && (
                              <small className="text-red-500">{error}</small>
                            )}

                            {/* <div className="flex flex-col flex-1 w-full">
                              <label className="text-gray-2500 placeholder:text-[#a8a4a4] font-medium mb-1.5">
                                Phone number
                              </label>

                              <div className="custom-input">
                                <PhoneInput
                                  inputClass="text-gray-600 placeholder:text-[#a8a4a4] !text-xs md:!text-sm lg:!text-base font-normal custom-input placeholder-gray-1600 border-solid border-gray-1400 border-[0.5px]"
                                  country={"us"}
                                  value={values.phone}
                                  placeholder="Enter your phone number"
                                  inputProps={{
                                    name: "phone",
                                  }}
                                  onChange={(phoneNumber, country, e) => {
                                    setFieldValue("phone", phoneNumber);
                                  }}
                                />
                              </div>
                              {errors.phone && touched.phone ? (
                                <small className="text-red-500">
                                  {errors.phone}
                                </small>
                              ) : null}
                            </div> */}

                            <div className="flex">
                              <button
                                type={"submit"}
                                disabled={loading || !(isValid && dirty)}
                                className={`disabled:cursor-not-allowed dicom-btn border-[#8082e6] text-white py-2 mt-3.5 mb-2.5 text-base tracking-tighter_1 shadow-lg font-semibold rounded-full w-full hover:border-[#6362bf] hover:bg-[#6362bf] font-manrope`}
                                {...rest}
                              >
                                {loading ? (
                                  <div className="flex justify-center w-6 h-6 m-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent"></div>
                                ) : (
                                  <>
                                    {uid === "youtube-vos-dataset"
                                      ? "Download"
                                      : _data?.form_bottom_text?.text}
                                  </>
                                )}
                              </button>
                            </div>
                            <div
                              className="px-2 text-xs text-center text-gray-600 font-inter custom-link lg:px-5"
                              dangerouslySetInnerHTML={{
                                __html: _data?.form_sub_text?.html,
                              }}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </section>
              </div>
            </section>
          </article>
        </div>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query PrismicWhitepaperLpPage($uid: String!) {
    prismicWhitepaperLpPage(uid: { eq: $uid }) {
      uid
      data {
        card_description {
          html
          text
        }
        card_heading {
          text
        }
        card_image {
          alt
          url
          dimensions {
            height
            width
          }
        }
        form_bottom_text {
          text
        }
        form_heading {
          text
        }
        form_sub_text {
          html
        }
        hero_image {
          url
          alt
          dimensions {
            height
            width
          }
        }
        hero_video {
          url
        }
        meta_description {
          text
        }
        meta_heading {
          text
        }
        page_description {
          html
        }
        page_heading {
          text
        }
        hs_form_id {
          text
        }
        form_class {
          text
        }
      }
    }
  }
`;

export default Whitepaper;

export const Head = ({ data }) => {
  const _data = data?.prismicWhitepaperLpPage?.data || {};
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};
